/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                // hide background image in small screens. Can't be done by css only
                function hideBackgroundImage() {
                    var header = $("header .container-fluid");
                    if ($(window).width() < 544) {
                        if(typeof  header.data("background-image") === 'undefined') {
                            header.data("background-image", header.css('background-image'));
                        }
                        header.css({'background-image': "none"});
                    }
                    else {
                        if(header.css("background-image") === 'none'){
                            header.css({'background-image': header.data("background-image")});
                        }
                    }
                }
                hideBackgroundImage();
                $(window).on("resize", function(){
                    hideBackgroundImage();
                });


            },
            finalize: function () {
                // animate menu open
                $(window).on("menu-open", function (e) {
                    var that = $('.c-hamburger'),
                        target =$(that.data('target'));
                    target.animate({
                        left: 0
                    },{duration:400,queued:false},function(){});

                    that.animate({
                        left: ($(window).width() * 0.7 - 35)
                    },{duration:400,queued:false},function(){});
                });
                // animate menu close
                $(window).on("menu-close", function (e) {
                    var that = $(".c-hamburger"),
                        target =$(that.data('target'));
                    target.animate({
                        left: "-70%"
                    },{duration:400,queued:false},function(){});
                    that.animate({
                        left: 0
                    },{duration:400,queued:false},function(){});
                });
                // check for svg support
                if (!Modernizr.svg) {
                    $("img[src$='.svg']")
                        .attr("src", $(this).attr("src").replace("/svg/", "png"));
                }

            }

        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
