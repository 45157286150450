/**
 * Created by tmen on 15.12.16.
 */
(function($) {

    "use strict";

    function toggleHandler(toggle) {
        toggle.addEventListener( "click", function(e) {
            e.preventDefault();
            if(this.classList.contains("is-active") === true){
                $(window).trigger('menu-close');
                this.classList.remove("is-active");
            }
            else {
                $(window).trigger('menu-open');
                this.classList.add("is-active");
            }
        });
    }

    var toggles = document.querySelectorAll(".c-hamburger");

    for (var i = toggles.length - 1; i >= 0; i--) {
        var toggle = toggles[i];
        toggleHandler(toggle);
    }

})(jQuery);